<template>
  <div class="apply-warp">
    <div class="table-wrap">
      <a-table
        :scroll="{ x: 1140 }"
        :columns="columns"
        rowKey="applyId"
        :data-source="dataList"
        :pagination="false"
      >
        <template #folder="{ record }">
          <a-tooltip placement="topLeft">
            <template #title>{{ record.folderPath }}</template>
            {{ record.folderName }}
          </a-tooltip>
        </template>
        <template #attachments="{ record }">
          <a-tooltip placement="topLeft" v-if="record.attachments">
            <template #title>{{
              record.attachments[0].fileName +
              record.attachments[0].filePath.substr(
                record.attachments[0].filePath.lastIndexOf(".")
              )
            }}</template>
            <span class="down" @click="viewOrDownFile(record.attachments[0])">
              {{
                record.attachments[0].fileName +
                record.attachments[0].filePath.substr(
                  record.attachments[0].filePath.lastIndexOf(".")
                )
              }}
            </span>
          </a-tooltip>
        </template>
        <template #applyTime="{ text }">
          <span>{{ dateFormat(text) }}</span>
        </template>
        <template #status="{ text }">
          <span v-if="text == 1">{{ $t('CM_Pass') }}</span>
          <!-- 通过 -->
          <span v-else-if="text == 3">{{ $t('teacher.rejected') }}</span>
          <!-- 已驳回 -->
          <span v-else>{{ $t('teacher.under_review') }}</span>
          <!-- 审核中 -->
        </template>
        <template #action="{ record }">
          <div class="action">
            <a-button type="link" @click="toDetail(record)"
              >{{ $t('cm_view') }}</a-button
            >
            <!-- 查看 -->
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import { teacherApplyList } from "@/api/teacher";
import { dateFormat, viewOrDownFile } from "@/utils/tools";
export default {
  setup() {
    const { t: $t } = useI18n();
    const router = useRouter();
    const state = reactive({
      columns: [
        {
          title: $t('teacher.apply_level'),
          // title: "申请等级",
          dataIndex: "levelName",
          width: 150,
          ellipsis: true,
        },
        {
          title: $t('teacher.lecturer_classification'),
          // title: "讲师分类",
          width: 200,
          ellipsis: true,
          slots: { customRender: "folder" },
        },
        {
          title: $t('teacher.attachment'),
          // title: "课件附件",
          width: 300,
          ellipsis: true,
          slots: { customRender: "attachments" },
        },
        {
          title: $t('teacher.apply_status'),
          // title: "申请状态",
          dataIndex: "status",
          width: 100,
          slots: { customRender: "status" },
        },
        {
          title: $t('teacher.apply_time'),
          // title: "申请时间",
          dataIndex: "applyTime",
          width: 200,
          slots: { customRender: "applyTime" },
        },
        {
          title: $t('cm_operate'),
          // title: "操作",
          dataIndex: "action",
          width: 100,
          slots: { customRender: "action" },
        },
      ],
      dataList: [],
    });

    teacherApplyList().then((res) => {
      state.dataList = res.data || [];
    });

    const toDetail = (data) => {
      router.push({
        path: "/teacher/applyDetail",
        query: {
          applyId: data.applyId,
        },
      });
    };

    return {
      dateFormat,
      ...toRefs(state),
      viewOrDownFile,
      toDetail,
    };
  },
};
</script>

<style lang="less" scoped>
.apply-warp {
  .table-wrap {
    .down {
      color: @color-theme;
      cursor: pointer;
    }
    ::v-deep(.ant-table-pagination.ant-pagination) {
      float: none;
      text-align: center;
    }
    .action {
      ::v-deep(.ant-btn) {
        padding-left: 0;
      }
    }
  }
}
</style>